import { useState } from "react";
import StatusCard from "./StatusCard";
import { Tab } from "@headlessui/react";
import { classNames } from "../util";
import DevicesTable from "./DevicesTable";

function DeviceStatus({ devicesStatus }) {
  let [categories] = useState(['All', "Success", "Error"]);
  return (
    <>
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
        <StatusCard
          statusName="Tablets OK"
          count={devicesStatus.success_count}
        />
        <StatusCard
          statusName="Tablets not found"
          count={devicesStatus.not_found_error_count}
          variant="error"
        />
        <StatusCard
          statusName="Tablets not paired"
          count={devicesStatus.pairing_error_count}
          variant="error"
        />
        <StatusCard
          statusName="App version wrong"
          count={devicesStatus.wrong_version_error_count}
          variant="error"
        />
      </div>
      <Tab.Group defaultIndex={2}>
        <Tab.List className="flex p-1 space-x-1 rounded-xl">
          {categories.map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm leading-5 font-medium rounded-lg",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-white ring-green-500 ring-opacity-60",
                  selected
                    ? "text-white bg-green-500"
                    : "text-black hover:text-green-500"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel>
            <DevicesTable devices={devicesStatus.devices}/>
          </Tab.Panel>
          <Tab.Panel>
            <DevicesTable devices={devicesStatus.devices.filter(d => d.status === "success")}/>
          </Tab.Panel>
          <Tab.Panel>
            <DevicesTable devices={devicesStatus.devices.filter(d => d.status !== "success")}/>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}

export default DeviceStatus;
