function Loader() {
  return (
    <div className="flex justify-center items-center flex-col pt-10">
      <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>{" "}
      <h1 className="pt-5 font-bold font text-base dark:text-white">Loading, please wait a moment</h1>
    </div>
  );
}

export default Loader;
