import { useState } from "react";
import Loader from "./components/Loader";
// import Toggle from "./components/Toggle";
import DeviceStatus from "./components/DevicesStatus";
import Alert from "./components/Alert";

function setDeviceStatus(name, status) {
  return {
    name, status
  }
}

function formatData(response) {
  const data = {
    success_count: response.success.count,
    not_found_error_count: response.error.not_found.count,
    pairing_error_count: response.error.pairing.count,
    wrong_version_error_count: response.error.wrong_version.count,
    devices: []
  }
  data.devices = [
    ...response.success.name.map((el) => setDeviceStatus(el, "success")),
    ...response.error.not_found.name.map((el) => setDeviceStatus(el, "not found")),
    ...response.error.pairing.name.map((el) => setDeviceStatus(el, "pairing")),
    ...response.error.wrong_version.name.map((el) => setDeviceStatus(el, "wrong version"))
  ]
  return data;
}

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [devicesStatus, setDevicesStatus] = useState();
  // const [isDarkMode, setDarkMode] = useState(false);
  const [error, setError] = useState(null);

  const startWatchdog = () => {
    setIsLoading(true);
    setDevicesStatus(null);
    fetch(
      "https://us-central1-fvdolphins.cloudfunctions.net/watchdog?timeout=5000",
      { strictErrors: true }
    )
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              body: json,
            })
          )
        );
      })
      .then(({ body, status }) => {
        if (status === 200) {
          setDevicesStatus(formatData(body));
          setError(null);
        } else {
          setError(body.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    // <div className={`${isDarkMode && "dark"}`}>
    <div className="min-h-screen bg-gray-100 dark:bg-gray-700">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                Tablet Check
              </h1>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              {/* <Toggle onChange={(status) => setDarkMode(status)} /> */}
            </div>
          </div>
        </div>
      </header>

      <Alert error={error} onClose={() => setError(null)}/>

      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div
            className="bg-gray-50 dark:bg-gray-800 border-t-4 border-green-500 rounded-b dark:text-white px-4 py-3 shadow-md flex"
            role="alert"
          >
            <div className="flex flex-1">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-green-500 mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">Check tablet status</p>
                <p className="text-sm">This can take up to 20 seconds.</p>
              </div>
            </div>
            <button
              type="button"
              onClick={() => startWatchdog()}
              disabled={isLoading}
              className={`-mr-1 px-4 py-2 sm:-mr-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-white ring-green-500 ring-opacity-60${
                isLoading && " opacity-50 cursor-not-allowed"
              }`}
            >
              Start
            </button>
          </div>
          <div className="px-4 py-6 sm:px-0">
            {isLoading && <Loader />}
            {devicesStatus && <DeviceStatus devicesStatus={devicesStatus} />}
          </div>
        </div>
      </main>
    </div>
    // </div>
  );
}

export default App;
